import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApacService {
  countryNameSubject = new ReplaySubject<string>();
  localeIdSubject = new ReplaySubject<string>();
  tenantId = "1"
  country: string = "";
  locale: string = "";
  environment = environment;  

  public country$ = new BehaviorSubject<any>(undefined);
  public countryObservable$ = this.country$.asObservable();
  public lng$ = new BehaviorSubject<any>(undefined);
  public lngObservable$ = this.lng$.asObservable();

  public languages$ = new BehaviorSubject<any>([]);
  public languagesObservable$ = this.languages$.asObservable();

  public contents$ = new BehaviorSubject<any>({});
  public contentsObservable$ = this.contents$.asObservable();
  constructor(private httpClient: HttpClient) {}

  httpHeaders(): HttpHeaders {
    return new HttpHeaders({
      "X_TENANT_ID": this.tenantId,
      "COUNTRY_NAME": this.country,
      "LOACLE_ID": this.locale
    })
  }

  httpHeadersJson(): HttpHeaders {
    return new HttpHeaders({
      "X_TENANT_ID": this.tenantId,
      "COUNTRY_NAME": this.country,
      "LOACLE_ID": this.locale,
      'content-type': 'application/json'
    })
  }

  getTenantContent(tenantId: string,country : string,locale: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl+`api/tenant/content`, {
      headers: {
        "X_TENANT_ID": tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale
      }
    });
  }

  getTenantContents(): Observable<any> {
    return this.httpClient.get(environment.baseUrl+`api/tenant/content`);
  }

  getStoreDetails(tenantId: string, lat: any, lng: any,record: number, distance: number,  type: string, page: number, country:string, locale : string, sellertype :string,
    currentLocationName : any
    ): Observable<any> {
    localStorage.setItem("lat", lat);
    localStorage.setItem("lng", lng);
    localStorage.setItem("currentLocationName", currentLocationName);
    return this.httpClient.get(`${environment.baseUrl}api/ui/store/radius?type=${type}&records=${record}&distance=${distance}&lat=${lat}&lng=${lng}&page=${page}&sellertype=${sellertype}`, {
      headers: {
        "X_TENANT_ID": tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale
      }
    })
  } 

  getStoreDetailsByPlaceId(tenantId: string, placeId: any, distance: number, record: number, type: string, page: number, country:string, locale : string, sellertype :string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/store/radius?type=${type}&records=${record}&distance=${distance}&placeId=${placeId}&page=${page}&sellertype=${sellertype}`, {
      headers: {
        "X_TENANT_ID": this.tenantId,
        "COUNTRY_NAME":country,
        "LOACLE_ID": locale
      }
    })
  }

  getformattedAddress(formattedAddress: string, tenantId: string, country:string, locale : string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/store/page/${formattedAddress}`, {
      headers: {
        "X_TENANT_ID": this.tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale
      }
    })
  }

  getStoreByLocationId(locationId: string, tenantId: string,country:string, locale : string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/location/${locationId}?type=s`, {
      headers: {
        "X_TENANT_ID": this.tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale
      }
    })
  }

  autoSearch(place: string, tenantId: string, country:string, locale : string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/auto-search/${place}`, {
      headers: {
        "X_TENANT_ID": this.tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale
      }
    })
  }

  getLocationByIp(): Observable<any> {
    const httpParam = new HttpParams().set('key', 'N7pS0QyVGJ09X0h');
    return this.httpClient.get<any>('https://pro.ip-api.com/json', {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      params: httpParam
    })
  }

  res: any;

  /* getCityByLatLng(lat: any, lng: any): Observable<any> {
    return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyD6emsHoKqSzFPnHJG8Kyf6T9PcDBIf4pQ&components=postal_code:&sensor=true&latlng=${lat},${lng}`)
  } */
 
  getCityByLatLng(lat: any, lng: any): Observable<any> {
    return this.httpClient.get(environment.baseUrl + `api/ui/location/${lat}/${lng}`, {headers : {
      "X_TENANT_ID": "1"
    }})
  }
  saveLead(lead: any, tenantId: string, country :string, locale : string ): Observable<any> {
    const body = JSON.stringify(lead);
    return this.httpClient.post(`${environment.baseUrl}api/ui/landing-page/` + window.location.search, body,
      {
        headers: {
          "X_TENANT_ID": this.tenantId,
          "COUNTRY_NAME": country,
          "LOACLE_ID": locale,
        'content-type': 'application/json'
        }
      })
  }

  getStoreListWithInKm(type: string, lat: any, lang: any, distance: number,country :string, locale : string , sellertype : string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/storeCount/${type}?distance=25&lat=${lat}&lng=${lang}&sellertype=${sellertype}`,
      {
        headers: {
          "X_TENANT_ID": this.tenantId,
          "COUNTRY_NAME": country,
          "LOACLE_ID": locale,
        'content-type': 'application/json'
        }
      })
  }
  submitGetacallForm(leadDetails: any, country :string, locale : string ) {
    return this.httpClient.post(`${environment.baseUrl}api/ui/landing-page/`, leadDetails, {
      headers: {
        "X_TENANT_ID": this.tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale
      }
    })
  }

  storeLocatorPageLead(lead: any, sourceName: string, tenantId: any, country :string, locale : string ): Observable<any> {
    const body = JSON.stringify(lead);
    const browserParams = window.location.search.length === 0 ? "?sourceName=" + sourceName : window.location.search + "&sourceName=" + sourceName;
    return this.httpClient.post(`${environment.baseUrl}api/ui/store-locator-page/${browserParams}`, body, {
      headers: {
        "X_TENANT_ID": this.tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale,
      'content-type': 'application/json'
      }
    });
  }
  submitPsuOffer(lead: string): Promise<any> {
    const body = JSON.stringify(lead);
    return this.httpClient.post(`${environment.baseUrl}api/ui/landing-page/`, body, { headers: this.httpHeaders() })
      .toPromise()
      .then();
  }

  supportedcountrylang(): Observable<any> {

    return this.httpClient.get(`${environment.baseUrl}api/ui/supportedcountrylang`, {
      headers: {
        "X_TENANT_ID": this.tenantId
      }
    })
  }
  paymentMethods(address : string, country: string, locale: string): Observable<any> {
    // address = "kerala-thrissur-ikkanda-warrior-rd-1214"
    return this.httpClient.get(`${environment.baseUrl}api/ui/store/page/${address}`, {
        headers: {
        "X_TENANT_ID": this.tenantId,
        "COUNTRY_NAME": country,
        "LOACLE_ID": locale
      }
    })
  }

  getPromotionsInfo(country:String,language:String,_for:String,type:String,slug:String):Observable<any>{
    return this.httpClient.get(`${environment.baseUrl}api/ui/${country}/${language}/${_for}/${type}/${slug}`, {
      headers: {
      "X_TENANT_ID": this.tenantId
    }
  })
  }

  getBlogsRedirectionUrl(name : string): Observable<any> {
    
    return this.httpClient.get(`${environment.baseUrl}api/ui/${name}`, {
      responseType: 'json',
      headers: {
        "X_TENANT_ID": this.tenantId
      }
    })
  }

 
}
