import { CarouselComponent } from 'angular-responsive-carousel';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SlidesutilService } from '../../services/slidesutil.service';
import { TranslateService } from '@ngx-translate/core';
import { ApacService } from 'src/app/services/apac.service';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

@Component({
  selector: 'app-get-assistance',
  templateUrl: './get-assistance.component.html',
  styleUrls: ['./get-assistance.component.css'],
  styles: [`
    ::ng-deep .carousel-cells .carousel-cell{
        cursor:  pointer !important;
    }
  `]
})
export class GetAssistanceComponent implements OnInit {

  @Input("country") country: String = "in";

  @ViewChild("innerAssistanceCaraousel") innerAssistanceCaraousel?: CarouselComponent;
  urls = [{ url: "/id/en/blogs/2022/12/02/hello-world/" }, { url: "/id/en/blogs/2022/12/02/hello-world/" }];




  constructor(public appComp: AppComponent, private slidesUtil: SlidesutilService, public translate1: TranslateService, private apac: ApacService) { }

  ngOnInit(): void {

    this.appComp.countrySub.subscribe((val: any) => {
      this.country = val;
      // this.getHomeLatAnLangFirstly(val);
      this.getBlogsRedirectionUrl(this.country, this.translate1.currentLang.toLowerCase());
    });
    this.getBlogsRedirectionUrl(this.country, this.translate1.currentLang.toLowerCase());
    this.getBlogsLangCode();
  }

  height = 400;
  ngAfterViewChecked() {
    this.reinitAssistance();
    $('.carousel-arrow-next').css('right', '-20px');
    $('.carousel-arrow-prev').css('left', '-20px');
    // $('.carousel-arrow-next').css('top', '175px');
    // $('.carousel-arrow-prev').css('top', '175px');
  }
  expression() {
    location.href = "/bookademo/"
  }

  expression1() {
    location.href = "https://www.lenovopcpal.com/"
  }


  bannerImages1 = [
    {
      path: "https://d3cqf5kijn47yv.cloudfront.net/assets/blog_banner_image.png"
    }

  ]

  reinitAssistance() {
    $('#getAssist').bxSlider({
      infiniteLoop: false,
      controls: true,
      auto: false,
      pause: 10000,
      minSlides: 1,
      maxSlides: 1,
      moveSlides: 1,
      slideWidth: this.slidesUtil.getSlideWidth(),
      touchEnabled: true
    });

    /* if (window.navigator.userAgent.toLowerCase().indexOf("chrome") > 0) {
        $("body").on("mousedown", ".bx-viewport .assistance.clickable", function (ev: any) {
            if ($(ev.currentTarget).attr("href") && $(ev.currentTarget).attr("href") != "#") {
                window.open($(ev.currentTarget).attr("href"), '_blank');
            }
        });
    } */
  }
  redirectionUrl: any;
  availableLangCodes: string[] = [];

  showPage(ev: any) {
    //return;
    const fullLangTag = this.translate1.currentLang.toLowerCase();

    if ($(ev.target).hasClass('carousel-arrow')) {
      return;
    }
    let url = null;
    if (this.availableLangCodes.includes(fullLangTag)) {
      url = `/${this.country}/${this.translate1.currentLang.toLowerCase()}/blogs/`;
      if (url) {
        window.open(url, '_blank');
      }
    } else {
      url = this.redirectionUrl;
      if (this.redirectionUrl) {
        window.open(this.redirectionUrl, '_blank');
      } else {
        window.open(url, '_self');
      }
    }
  }

  getCurrentSlide() {
    return this.innerAssistanceCaraousel?.slideCounter;
  }

  getBlogsRedirectionUrl(countryName: any, language: any) {
    this.apac.getBlogsRedirectionUrl("blogs_redirection_url?countryId=" + countryName + "&languageId=" + language).subscribe((data) => {
      this.redirectionUrl = data.url;
    },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getBlogsLangCode() {
    this.apac.getBlogsRedirectionUrl("bloge_redirection_lang_code").subscribe(
      (data) => {
        console.log("LC", data)
        this.availableLangCodes = data;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

}
