import {
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
    NgModule,
} from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RatingModule } from 'ng-starrating';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { PopupSurveyComponent } from './widgets/popup-survey/popup-survey.component';
import { CookieModule } from 'ngx-cookie';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingPageAddressesComponent } from './landing-page/landing-page-addresses/landing-page-addresses.component';
import { LandingPageFooterComponent } from './landing-page/landing-page-footer/landing-page-footer.component';
import { FileDropDirective } from './widgets/file-drop/file-drop.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CharactersOnlyDirective } from './directive/charactersOnly/characters-only.directive';
import { GetAssistanceComponent } from './widgets/get-assistance/get-assistance.component';

//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { PcPalWidgetComponent } from './widgets/pc-pal-widget/pc-pal-widget.component';
import { PdfViewerComponent } from './widgets/pdf-viewer/pdf-viewer.component';
import { EmojiScaleComponent } from './widgets/emoji-scale/emoji-scale.component';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { StorePromotionsComponent } from './widgets/store-promotions/store-promotions.component';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApacOffersComponent } from './widgets/apac-offers/apac-offers.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
      domain: window.location.hostname,
      name: 'cookie-consent'
    },
    palette: {
      popup: {
        background: '#808080',
        text:'#fff'
  
      },
      button: {
        background: 'rgb(0, 129, 204)',
        text:'#fff'
      }
    },
    theme: 'edgeless',
    type: 'opt-out',
    layout: 'my-custom-layout',
    layouts: {
      "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements: {
      messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}} 
        <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>
        <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
      </span>
      `,
    },
    content:{
      message: 'We use our own cookies and from third parties to enhance and provide you with the best experience on our website.',
    }
  };


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,

        PopupSurveyComponent,
        LandingPageComponent,
        LandingPageAddressesComponent,
        LandingPageFooterComponent,
        FileDropDirective,
        CharactersOnlyDirective,
        GetAssistanceComponent,
        PcPalWidgetComponent,
        PdfViewerComponent,
        EmojiScaleComponent,
        ApacOffersComponent,
        StorePromotionsComponent,
        PageNotFoundComponent
    ],
    imports: [
        NgcCookieConsentModule.forRoot(cookieConfig),
        FormsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        RatingModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            positionClass: 'toast-bottom-center',
        }),
        BrowserAnimationsModule,
        NgbModule,
        CommonModule,
        IvyCarouselModule,
        CookieModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ProgressbarModule.forRoot(),
        FormsModule,
        ClipboardModule,
        SharedModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //   enabled: environment.production,
        //   // Register the ServiceWorker as soon as the app is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [Meta,TranslateService,HomeComponent],
    bootstrap: [AppComponent],
    exports: [TranslateModule],
})
export class AppModule { }
