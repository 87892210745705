import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedRoutingModule } from './shared-routing.module';
import { RatingModule } from 'ng-starrating';
import { PartnerLoginComponent } from './partner-login/partner-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CustomCarouselComponent } from './custom-carousel/custom-carousel.component';

@NgModule({
    declarations: [HeaderComponent, FooterComponent, PartnerLoginComponent, CustomCarouselComponent],
    imports: [CommonModule, SharedRoutingModule, RatingModule, NgbModule, ReactiveFormsModule, FormsModule,
        TranslateModule.forRoot({
            loader: {
              provide:  TranslateLoader,
              useFactory:  HttpLoaderFactory,
              deps: [HttpClient]
            }
          })],
    exports: [
        HeaderComponent,
        FooterComponent,
        RatingModule,
        PartnerLoginComponent,
        ReactiveFormsModule,IvyCarouselModule, FormsModule,
        
    ],
    providers: [
      HeaderComponent,
      FooterComponent,
      RatingModule,
      PartnerLoginComponent,
      ReactiveFormsModule, FormsModule,
      
  ],
})
export class SharedModule {}
