import { LandingPageComponent } from "./landing-page/landing-page.component";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Store, StoresResponse, StoresRequest } from "./models/store";
import {
  Component,
  HostListener,
  Inject,
  Renderer2,
  OnInit,
  LOCALE_ID,
} from "@angular/core";
import {
  GeolocationService,
  GEOLOCATION_SUPPORT,
} from "@ng-web-apis/geolocation";
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ApiService } from "./services/api.service";
import { Location } from "./models/location";


import { UtilitiesService } from "./services/utilities.service";
import { HeaderComponent } from "./shared/header/header.component";
import { HomeComponent } from "./home/home.component";
import { info } from "console";
import { TranslateService } from "@ngx-translate/core";
import { Title, Meta } from '@angular/platform-browser';
import { AnyTxtRecord } from "dns";
import { ApacService } from "./services/apac.service";
import { NgcCookieConsentService, NgcStatusChangeEvent } from "ngx-cookieconsent";
import { CookieService } from "ngx-cookie";

declare var reCaptchaSiteKey: string;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  isLoad = true;
  title = "lenovo-ui";
  currentLocation!: Location;
  locationOn = new BehaviorSubject<boolean>(false);
  browserLocation = new BehaviorSubject<boolean>(false);
  storesResponse = new ReplaySubject<StoresResponse>();
  //apac
  apacStoresRes = new ReplaySubject();
  apacStoreWithKm = new ReplaySubject();

  localeSub = new ReplaySubject<string>();
  countrySub = new ReplaySubject<string>();
  discover = new BehaviorSubject<string>("stores");


  surveySourceId = "150";
  // captchaKey = reCaptchaSiteKey;
  captchaKey = "6LeVNc0ZAAAAALXgOTOmvFnwRXGzuCylXkquTEDD"; // dev
  placeId?: string;
  placeDesc?: string;
  buyOnlineLink?: string;
  recNum: number = 25;
  latitude: number | any = undefined;
  longitude: number | any = undefined;
  currentLocationName?: string;
  popCities?: any;
  surveyTriggered: boolean = false;
  landingPages: any[] = [];
  ctid: string = "8df21f141fd9f490478f1b64aa9f0d6f";
  offerType!: string;
  code!: string;
  surveyDisplayMode = "embedded";
  loadMore = 0;

  countryName = "";
  language = "en";
  blogRoute = "";
  localeId: string | any = "en-idx";
  lngs: any = [];
  selected: boolean = false;
  countryListShow = false;
  languageShowList = false;
  sellertype = "Exclusive";
  browserLocationApac = true;
  cityPopForUnSupported = false;
  languages: any[] = [];
  index: number = 0;
  countriesList = [] as any;
  currentCountry: string = "";

  constructor(
    private readonly geolocation$: GeolocationService,
    @Inject(GEOLOCATION_SUPPORT) private readonly geolocationSupport: boolean,
    private apiService: ApiService,
    private renderer: Renderer2,
    private router: Router,
    @Inject(LOCALE_ID) userLocale: string,
    private apac: ApacService,
    private route: ActivatedRoute,
    private utility: UtilitiesService,
    public translate: TranslateService,
    private titles: Title,
    private metaTagService: Meta,
    private ccService: NgcCookieConsentService,
    private cookieService:CookieService,

  ) {
    this.apac.getTenantContents().subscribe((value) => {
      this.apac.contents$.next(value);
      this.apac.supportedcountrylang().subscribe(conAndLang => {
        if (conAndLang.length > 0) {
          if (Array.isArray(conAndLang)) {
            this.countryListShow = true;
            this.languages = conAndLang.sort((a: object | any, b: object | any) => a.name.localeCompare(b.name));
            this.apac.languages$.next(this.languages);
          
            this.onPageLoad()
          }
        }
      })
    });

  }

  onPageLoad() {
    // this.isLoad = false;
    this.localeSub.subscribe((val) => {
      this.language = val;
      this.localeId = val + "- " + this.countryName;
      this.setNgxTranslation();
      this.langAndcountryChangeSubjets()

    });
    this.countrySub.subscribe((val) => {
      this.countryName = val;
      // this.getHomeLatAnLangFirstly(val);
    });
  }

  statusChangeSubscription!: Subscription;

  langAndcountryChangeSubjets() {
    this.popCities = null;
    this.apac.contentsObservable$.subscribe(val => {
      this.isLoad = false;
      let data = val[this.countryName];
      this.tenantContent = data;
      this.utility.banners.next(data.bannerimages.images);
      if (this.tenantContent && this.tenantContent.Meta) {
        this.metaTagService.updateTag({
            name: 'description',
            content: this.tenantContent.Meta.tags.descp
        });
        this.titles.setTitle(this.tenantContent.Meta.tags.title);
    }
 
      this.popCities = data.cities_to_display_when_location_off;
      this.checkLocationSettingCity(data.cities_to_display_when_location_off);
    })
  }

  languageChecks() {

  }
  ngOnInit() {
    
    let elemt =  document.getElementsByClassName("cc-deny")[0];
    elemt.innerHTML = "<img src='./assets/images/cancel1.png' width=15 height=15>";
    
    let cookie = this.cookieService.get("cookie-consent");
              if(cookie === "deny"){
               this.cookieService.remove("cookie-consent")
              }
    
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {  
           if(event.status === "deny"){
           let cookie = this.cookieService.get("cookie-consent");
              if(cookie === "deny"){
               this.cookieService.remove("cookie-consent")
              }
           }
     
      });
    this.getBlogsLangCode();
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Laptops, Best Laptops, Lenovo stores' },
      { name: 'robots', content: 'index, follow' },
      { name: 'writer', content: 'Dennis, SJ' },
      { charset: 'UTF-8' }
    ]);
    this.initSeoTags();
    this.loadScript(
      "https://maps.google.com/maps/api/js?key=" +
      environment.mapKey +
      "&sensor=true"
    ).then(() => {
    });
  
  }
  initSeoTags(){
    this.apac.contentsObservable$.subscribe(val => {
      let data = val[this.countryName];
      this.tenantContent = data;
        if (this.tenantContent && this.tenantContent.Meta) {
          this.metaTagService.updateTag({
              name: 'description',
              content: this.tenantContent.Meta.tags.descp
          });
          this.titles.setTitle(this.tenantContent.Meta.tags.title);
      }
    })
  }
  openFisrtTime = 0
  getHomeLatAnLang(countryName: string) {
    if (this.latitude !== undefined && this.longitude !== undefined && this.countryName !== undefined) {
      if (this.currentCountry == this.countryName && this.openFisrtTime == 0) {
        this.apac.contentsObservable$.subscribe(val => {
          this.isLoad = false;
          let data = val[this.countryName];
          this.tenantContent = data;
          this.utility.banners.next(data.bannerimages.images);
          this.popCities = data.cities_to_display_when_location_off;
          this.processcurrentLocation(this.popCities[0]);
        })
        this.locationChecking(this.latitude, this.longitude, true);
        this.openFisrtTime++;
      } else {
        this.locationCheckingHome(this.latitude, this.longitude, this.locationOn.value);
      }

    } else {
      this.getHomeLatAnLangFirstly(countryName);
    }

  }


  setNgxTranslation() {
    this.translate.setDefaultLang(this.language || 'en');
    this.translate.use(this.language);
  }


  getHomeLatAnLangFirstly(countryName: any) {
    this.apac.languagesObservable$.subscribe(conAndLang => {
      this.languages = conAndLang;
      let lang = navigator.language.split("-")[0];
      this.localeId = lang + "-" + countryName;
      let countCode = countryName;
      let langCode = "en";
      this.languages.forEach(con => {
        if (con.code === this.localeId.split("-")[1].toLocaleLowerCase()) {
          countCode = con.code;
          con.langList.forEach((l: any) => {
            if (l.code === this.localeId.split("-")[0].toLocaleLowerCase()) {
              langCode = l.code;
            }
          });
        }

      });
      this.localeId = langCode + "-" + countCode;
      this.countryName = this.localeId.split("-")[1].toLocaleLowerCase();
      this.language = this.localeId.split("-")[0].toLocaleLowerCase();
      this.apac.countryNameSubject.next(this.countryName);
      this.apac.localeIdSubject.next(this.localeId.split("-")[0].toLocaleLowerCase());
      this.setNgxTranslation();
      this.getContentForReload();
    })
  }

  getContentForReload() {
    this.popCities = null;
    this.apac.contentsObservable$.subscribe(val => {
      this.isLoad = false;
      let data = val[this.countryName];
      this.tenantContent = data;
      if (this.tenantContent && this.tenantContent.Meta) {
        this.metaTagService.updateTag({
            name: 'description',
            content: this.tenantContent.Meta.tags.descp
        });
        this.titles.setTitle(this.tenantContent.Meta.tags.title);
    }
      this.utility.banners.next(data.bannerimages.images);
      this.popCities = data.cities_to_display_when_location_off;
      this.checkLocationSettingCity(data.cities_to_display_when_location_off);
    })
  }

   isInt(value:any) {
    if (isNaN(value)) {
      return false;
    }
    var x = parseFloat(value);
    return (x | 0) === x;
  }


  SetLanguageAfterCountryDropdown(countryName: string, language: any) {
    this.sellertype = "Exclusive";
    this.index = 0;
    let urlString: any = null;
    
    let url = this.router.url;
    let urlArray = url.split("/");
    if ((urlArray.length > 0 && urlArray[3] == "store") || (urlArray.length > 0 && urlArray[3] == "blogs")) {
      urlArray[2] = language || 'en';

      if(urlArray[3] == "blogs"){
      if(typeof urlArray[4] !== 'undefined' &&  this.isInt(urlArray[4])) {
        urlArray.splice(4,1);
        this.blogRoute=urlArray[3];
      }
      }

      urlString = urlArray.join("/");
    }

    if (this.languages && this.languages.length > 0) {
      let lang = navigator.language.split("-")[0];
      this.localeId = lang + "-" + countryName;
      // let found1 = false;
      let countCode = countryName;
      let langCode = language ? language : "en";
      if (!language) {
        this.languages.forEach(con => {
          if (con.code === this.localeId.split("-")[1].toLocaleLowerCase()) {
            // found1 = true;
            countCode = con.code;
            con.langList.forEach((l: any) => {
              if (l.code === this.localeId.split("-")[0].toLocaleLowerCase()) {
                // found = true;
                langCode = l.code;
              }
            });
          }
        });
      }
      
      this.localeId = langCode + "-" + countCode;
      this.countryName = this.localeId.split("-")[1].toLocaleLowerCase();
      this.language = this.localeId.split("-")[0].toLocaleLowerCase();
      this.apac.countryNameSubject.next(this.countryName);
      this.apac.localeIdSubject.next(this.localeId.split("-")[0].toLocaleLowerCase())
      this.setNgxTranslation();
      if (urlString && language != null) {
        this.router.navigate([urlString]);
    // window.location.href = urlString
      } else {
        this.router.navigate([
          this.countryName + "/" + this.language+"/"+this.blogRoute
        ])
      }


    } else {
      this.apac.languagesObservable$.subscribe(conAndLang => {
        this.languages = conAndLang;
        let lang = navigator.language.split("-")[0];
        this.localeId = lang + "-" + countryName;
        let countCode = countryName;
        let langCode = language ? language : "en";

        if (!language) {
          this.languages.forEach(con => {
            if (con.code === this.localeId.split("-")[1].toLocaleLowerCase()) {
              countCode = con.code;
              con.langList.forEach((l: any) => {
                if (l.code === this.localeId.split("-")[0].toLocaleLowerCase()) {
                  langCode = l.code;
                }
              });
            }
          });
        }

        this.localeId = langCode + "-" + countCode;
        this.countryName = this.localeId.split("-")[1].toLocaleLowerCase();
        this.language = this.localeId.split("-")[0].toLocaleLowerCase();
        this.apac.countryNameSubject.next(this.countryName);
        this.apac.localeIdSubject.next(this.localeId.split("-")[0].toLocaleLowerCase())
        this.setNgxTranslation();
        if (urlString && language != null) {
          this.router.navigate([urlString]);
          // window.location.href = urlString
        } else {
          this.router.navigate([
            this.countryName + "/" + this.language
          ])
        }
      })

    }

  }

  setCountryAndLang() {
    this.geolocation$.pipe(take(1)).subscribe(
      position => {
        let latitude = this.latitude = position.coords.latitude;
        let longitude = this.longitude = position.coords.longitude;
        this.apac.getCityByLatLng(latitude, longitude).subscribe(country => {
          if (country.status == "OK") {
            Array.from(country.results[0].address_components)
              .forEach((element: any) => {
                Array.from(element.types).forEach(v => {
                  if (v == "country") {
                    this.countryName = element.short_name.toLowerCase();
                    this.apac.languagesObservable$.subscribe(conAndLang => {
                      this.languages = conAndLang;
                      let lang = navigator.language.split("-")[0];
                      this.localeId = lang + "-" + this.countryName;
                      let countCode = "ix";
                      let langCode = lang;
                      this.languages.forEach(con => {
                        if (con.code === this.localeId.split("-")[1].toLocaleLowerCase()) {
                          countCode = con.code;
                          con.langList.forEach((l: any) => {
                            if (l.code === this.localeId.split("-")[0].toLocaleLowerCase()) {
                              langCode = l.code;
                              return;
                            } else {
                              langCode = "en";
                            }
                          });
                        } else {
                          countCode = this.countryName;
                          langCode = "en";
                        }
                      });
                      this.localeId = langCode + "-" + countCode;
                      this.countryName = this.localeId.split("-")[1].toLocaleLowerCase();
                      this.language = this.localeId.split("-")[0].toLocaleLowerCase();
                      let existingCountry = this.languages.filter(c => c.code === this.countryName)

                      this.setNgxTranslation();

                      if (existingCountry.length > 0) {
                        this.apac.countryNameSubject.next(this.countryName);
                        this.apac.localeIdSubject.next(this.localeId.split("-")[0].toLocaleLowerCase())
                        let url = this.router.url;
                        let urlArray = url.split("/");
                        if (urlArray[3] != "store") {
                          this.router.navigate(["/" + this.countryName + "/" + this.localeId.split("-")[0].toLocaleLowerCase()]);
                          return;
                        }
                        // this.checkLocationSetting();
                        this.latitude = latitude;
                        this.longitude = longitude;
                        this.locationChecking(this.latitude, this.longitude, true);
                        this.getContent();
                      } else {
                        this.isLoad = false;
                        this.router.navigate(["/select-country"]);
                      }
                    })
                  }
                })
              });

            Array.from(country.results).forEach((components: any) => {
              Array.from(components.address_components)
                .forEach((element: any) => {
                  Array.from(element.types).forEach(v => {
                    if (v == 'locality') {
                      this.currentLocationName = element.short_name;
                    }
                  })
                });
            })
          }
        },
          err => {
            this.isLoad = false;
          })
      }, err => {

        this.apac.getLocationByIp().subscribe(countyDetail => {
          this.countryName = countyDetail.countryCode.toLowerCase();
          this.apac.languagesObservable$.subscribe(conAndLang => {
            this.languages = conAndLang;
            let lang = navigator.language.split("-")[0];
            this.localeId = lang + "-" + this.countryName;
            let countCode = "ix";
            let langCode = lang;
            this.languages.forEach(con => {
              if (con.code === this.localeId.split("-")[1].toLocaleLowerCase()) {
                // found1 = true;
                countCode = con.code;
                con.langList.forEach((l: any) => {
                  if (l.code === this.localeId.split("-")[0].toLocaleLowerCase()) {
                    // found = true;
                    langCode = l.code;
                    return;
                  } else {
                    langCode = "en";
                  }
                });
              } else {
                countCode = this.countryName;
                langCode = "en";
              }
            });
            this.localeId = langCode + "-" + countCode;
            this.countryName = this.localeId.split("-")[1].toLocaleLowerCase();
            this.language = this.localeId.split("-")[0].toLocaleLowerCase();
            let existingCountry = this.languages.filter(c => c.code === this.countryName);

            this.setNgxTranslation();

            if (existingCountry.length > 0) {
              this.countryName = this.localeId.split("-")[1].toLocaleLowerCase();
              this.apac.countryNameSubject.next(this.countryName);
              this.apac.localeIdSubject.next(this.localeId.split("-")[0].toLocaleLowerCase())
              this.router.navigate(["/" + this.localeId.split("-")[1].toLocaleLowerCase() + "/" + this.localeId.split("-")[0].toLocaleLowerCase()]);
              this.latitude = countyDetail.lat;
              this.longitude = countyDetail.lng;
              this.locationChecking(this.latitude, this.longitude, false);
              // this.getContent();
            } else {
              this.isLoad = false;
              // this.router.navigate(["/select-country"]);
            }
          })
        })
      }
    )
  }

  locationChecking(latitude: any, longitude: any, location: boolean) {
    this.locationOn.next(location);
    this.setUserLocation(
      new Location(latitude, longitude)
    );
    this.browserLocation.next(true);
    // this.getContentForReload();
    this.getImages();
  }

  getImages() {
    this.apac.contentsObservable$.subscribe(val => {
      this.isLoad = false;
      let data = val[this.countryName];
      if (data != undefined) {
        this.tenantContent = data;
        this.utility.banners.next(data.bannerimages.images);
      }

    })
  }

  locationCheckingHome(latitude: any, longitude: any, location: boolean) {
    this.locationOn.next(location);
    // this.setUserLocation(
    //   new Location(latitude, longitude)
    // );
    this.browserLocation.next(true);
    this.getContentForReload();
  }


  checkLocationSetting() {
    this.geolocation$.pipe(take(1)).subscribe(
      (position) => {
        this.locationOn.next(true);
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.setUserLocation(
          new Location(position.coords.latitude, position.coords.longitude)
        );
        this.browserLocationApac = true;
        this.browserLocation.next(true);
      },
      (error) => {
        this.locationOn.next(false);
        this.apiService.getLocationByIp().then((location: Location) => {
          this.setUserLocation(location);
          this.latitude = location.lat;
          this.longitude = location.lng;
          this.browserLocationApac = false;
          this.browserLocation.next(false);
        });
      }
    );
  }

  checkLocationSettingCity(value: any) {
    this.latitude = value[0].lat;
    this.longitude = value[0].lng;
    this.setUserLocation(
      new Location(this.latitude, this.longitude)
    );
    this.processcurrentLocation(value[0]);


  }

  tenantContent: any;
  getContent() {
    this.popCities = null;
    this.apac.contentsObservable$.subscribe(val => {
      this.isLoad = false;
      let data = val[this.countryName];
      this.tenantContent = data;
      this.utility.banners.next(data.bannerimages.images);
      this.popCities = data.cities_to_display_when_location_off;
      this.checkLocationSettingCity(data.cities_to_display_when_location_off);
    })
  }

  processcurrentLocation(result: any) {
    if (result) {
      this.currentLocationName = result.city;
    }
  }

  setUserLocation(location: Location) {
    this.currentLocation = location;
    let request: StoresRequest;

    if (this.discover.value === "stores") {
      request = new StoresRequest(
        location.lat,
        location.lng,
        2,
        this.recNum,
        0
      );
      this.getStoresBasedOnLocation(request);
    } else if (this.discover.value === "service centers") {
      request = new StoresRequest(
        location.lat,
        location.lng,
        4,
        this.recNum,
        0
      );
      this.getServiceCentersBasedOnLocation(request);
    }
  }

  tenantId: string = "1";
  record: number = 26;
  type: string = "s";
  distance: number = 3000;
  page = 0;
  //apac
  i = 0;
  getStoresBasedOnLocation(request: StoresRequest) {
    this.isLoad = true;
    let sellertype = this.sellertype;
    setTimeout(() => {
      forkJoin(this.apac.getStoreDetails(this.tenantId, request.lat, request.lng, this.record, this.distance, "s", this.page, this.countryName, this.localeId.split("-")[0], sellertype, this.currentLocationName),
        this.apac.getStoreListWithInKm(this.type, request.lat, request.lng, this.distance, this.countryName, this.localeId.split("-")[0], sellertype)).subscribe(([res, val]) => {
          this.isLoad = false;
          if (res.length == 0 && sellertype == "Exclusive") {
            sellertype = this.sellertype = "Reseller";
            this.index = 1;
            forkJoin(this.apac.getStoreDetails(this.tenantId, request.lat, request.lng, this.record, this.distance, "s", this.page, this.countryName, this.localeId.split("-")[0], sellertype, this.currentLocationName),
              this.apac.getStoreListWithInKm(this.type, request.lat, request.lng, this.distance, this.countryName, this.localeId.split("-")[0], sellertype)).subscribe(([res, val]) => {
                if (res.length == 0 && sellertype == "Reseller") {
                  sellertype = this.sellertype = "Gaming";
                  this.index = 2;
                  forkJoin(this.apac.getStoreDetails(this.tenantId, request.lat, request.lng, this.record, this.distance, "s", this.page, this.countryName, this.localeId.split("-")[0], sellertype, this.currentLocationName),
                    this.apac.getStoreListWithInKm(this.type, request.lat, request.lng, this.distance, this.countryName, this.localeId.split("-")[0], sellertype)).subscribe(([res, val]) => {
                      if (res.length == 0 && sellertype == "Gaming") {
                        sellertype = this.sellertype = "";
                        this.index = 3;
                        forkJoin(this.apac.getStoreDetails(this.tenantId, request.lat, request.lng, this.record, this.distance, "s", this.page, this.countryName, this.localeId.split("-")[0], sellertype, this.currentLocationName),
                          this.apac.getStoreListWithInKm(this.type, request.lat, request.lng, this.distance, this.countryName, this.localeId.split("-")[0], sellertype)).subscribe(([res, val]) => {
                            this.tabSelected(this.index)
                            this.loadMore = res.length;
                            this.apacStoresRes.next(res);
                            this.apacStoreWithKm.next(val);
                            setTimeout(() => {
                              this.countryListShow = false;
                            }, 300)
                          })
                      } else {
                        this.tabSelected(this.index)
                        this.loadMore = res.length;
                        this.apacStoresRes.next(res);
                        this.apacStoreWithKm.next(val);
                        setTimeout(() => {
                          this.countryListShow = false;
                        }, 300)
                      }
                    })
                } else {
                  this.tabSelected(this.index)
                  this.loadMore = res.length;
                  this.apacStoresRes.next(res);
                  this.apacStoreWithKm.next(val);
                  setTimeout(() => {
                    this.countryListShow = false;
                  }, 300)
                }
              })
            setTimeout(() => {
              this.countryListShow = false;
            }, 300)
          } else {
            this.tabSelected(this.index)
            this.loadMore = res.length;
            this.apacStoresRes.next(res);
            this.apacStoreWithKm.next(val);
            setTimeout(() => {
              this.countryListShow = false;
            }, 300)
          }
        }, err => {
          this.isLoad = false;

        })
    }, 0);
  }

  tabSelected(index: number) {
    this.loadMore = 0;
    
    for (var i = 0; i < 4; i++) {
  
      let listDesk = document.getElementsByClassName('list');
      let listMob = document.getElementsByClassName('list1');

        if (i == index) {
          if(listDesk && listDesk.length > 0)
                document.getElementsByClassName('list')[index].classList.add("active");
          //mob
          (listMob && listMob.length > 0)
               document.getElementsByClassName('list1')[index].classList.add("active");
        } else {
          if(listDesk && listDesk.length > 0)
               document.getElementsByClassName('list')[i].classList.remove("active");
          //mob
          (listMob && listMob.length > 0)
                document.getElementsByClassName('list1')[i].classList.remove("active");
        }
   
    }
  }

  getServiceCentersBasedOnLocation(request: StoresRequest) {
    this.apac
      .getStoreDetails(
        this.tenantId,
        request.lat,
        request.lng,
        this.record,
        this.distance,
        "sc",
        this.page,
        this.countryName, this.localeId.split("-")[0],
        this.sellertype,
        this.currentLocationName
      )
      .subscribe((res) => {
        this.apacStoresRes.next(res);
        this.apac
          .getStoreListWithInKm(
            this.type,
            request.lat,
            request.lng,
            this.distance,
            this.countryName, this.localeId.split("-")[0],
            this.sellertype
          )
          .subscribe((val) => {
            this.apacStoreWithKm.next(val);
          });
      });
    this.apiService
      .getServiceCentersBasedOnLocation(request)
      .then((response) => {
        this.storesResponse.next(response);
      });
  }

  getAppSettings() {
    this.popCities = null;
    this.apiService.getAppSettings().then((response) => {
      if (response.status === "SUCCESS") {
        if (response.captchakey) {
          this.captchaKey = response.captchakey;
        }
        if (response.buyOnlineLink) {
          this.buyOnlineLink = response.buyOnlineLink;
        }
        if (response.cities) {
          this.popCities = response.cities;
        }
      }
    });
  }

  loadScript(name: string) {
    return new Promise((resolve: any, reject) => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = name;
      document.getElementsByTagName("head")[0].appendChild(script);
      resolve();
    });
  }

  redirectionUrl: any;
  availableLangCodes:String[]=[];
  
  getBlogsLangCode(){
    // this.apac.getBlogsRedirectionUrl("bloge_redirection_lang_code").subscribe((data)=>{
    //   this.availableLangCodes=data;
    // },
    // (err:any)=>{
    //   console.log(err)
    // })
  }
}