<div class="wrapper" style="background-color: #e6e2e4;">

    <header id="header1">
        <div class="container clearfix">
            <a href="javascript:;" (click)="go('home')" class="logo">
                <img src="/assets/images/lenovo-apac.webp" width="" height="" alt="" class="img-fluid" loading="lazy" />
            </a>


            <!-- -->
            <div class="main-nav-bx">
                <a href="javascript:;" (click)="open()" class="nav-dot-icon d-lg-none">&nbsp;</a>
                <div class="main-nav clearfix">
                    <a href="javascript:;" (click)="close()" class="main-nav-close d-lg-none">&nbsp;</a>
                    <ul>
                        <li class="menu-item-1">
                            <a href="javascript:;" (click)="go('stores')">
                                <span class="menu-icon store-icon"></span>
                                <span style="left:3px;" class="menu-txt " id="overflow-inherit">{{
                                    "store-listing.header.discover-stores" | translate }}</span>
                            </a>
                        </li>
                        <li class="menu-item-1">
                            <a href="javascript:;" (click)="go('warranty')">
                                <span class="menu-icon warranty-icon"></span>
                                <span class="menu-txt " id="overflow-inherit">{{ "store-listing.header.warranty" |
                                    translate }}</span>
                            </a>
                        </li>
                        <li class="menu-item-1">
                            <a href="javascript:;" (click)="go('website')">
                                <span class="menu-icon globe-icon"></span>
                                <span class="menu-txt" id="overflow-inherit">{{ "store-listing.header.official-website"
                                    | translate }}</span>
                            </a>
                        </li>
                        <!-- <li class="menu-item-2 d-flex align-items-center justify-content-center">
                            
                            <div class="text-right country-region d-flex align-items-center justify-content-center" >
                            <img [src]="'./assets/flags/4x3/'+appComp.countryName+'.svg'" style="width: 6%; position: relative; left: 32px; z-index: 1; border: 1px solid #d8d8d8;"> 
                                <select name="lng" id="lng" *ngIf="!this.appComp.countryListShow" (change)="selectChange($event)" [(ngModel)]="this.appComp.countryName" class="country-select-bx">
                                    <option value="{{value.code}}" *ngFor="let value of countryLists">{{value.name}}</option>
                                 </select> 
                            </div>
                        </li> -->
                        <li class="d-lg-none"
                            style="height: 60px; border-top: 1px solid #8b8b8b; border-bottom: 1px solid #8b8b8b; padding: 4px 0;">
                            <p style="font-size: 0.8em; text-align: center;">{{ "store-listing.header.toll-free" |
                                translate }}</p>
                            <a style="font-size: 17px;" class="d-block" href="tel:{{
                                    appComp?.tenantContent?.header?.tel_number
                                }}">
                                <strong>{{
                                    appComp?.tenantContent?.header?.tel_number
                                    }}
                                </strong></a>
                        </li>
                        <li>
                            <div class="header-icons mr-3">
                                <ng-container *ngFor="
                                        let item of appComp?.tenantContent?.header
                                            ?.social
                                    ">
                                    <a href="{{ item?.link }}" target="_blank" class="social-icon"><img
                                            src="{{ item?.icon }}" width="20" height="20" alt="" class="img-fluid"
                                            loading="lazy" /></a>
                                </ng-container>

                                <!-- <img src="/assets/images/fb_icon.svg" alt="icon" />
                                <img
                                    src="/assets/images/linkedin_icon.svg"
                                    alt="icon"
                                />
                                <img
                                    src="/assets/images/twitter_icon.svg"
                                    alt="icon"
                                />
                                <img
                                    src="/assets/images/instragram_icon.svg"
                                    alt="icon"
                                />
                                <img
                                    src="/assets/images/youtube_icon.svg"
                                    alt="icon"
                                /> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="ml-auto d-flex align-items-center header-right-container">
                <div class="header-icons">
                    <ng-container *ngFor="let item of appComp?.tenantContent?.header?.social">
                        <a href="{{ item?.link }}" target="_blank" class="social-icon"><img src="{{ item?.icon }}"
                                width="20" height="20" alt="" class="img-fluid w-100" loading="lazy" />
                        </a>
                    </ng-container>
                    <!-- <img src="/assets/images/fb_icon.svg" alt="icon" />
                    <img src="/assets/images/linkedin_icon.svg" alt="icon" />
                    <img src="/assets/images/twitter_icon.svg" alt="icon" />
                    <img src="/assets/images/instragram_icon.svg" alt="icon" />
                    <img src="/assets/images/youtube_icon.svg" alt="icon" /> -->
                </div>

                <!-- <span
                    *ngIf="getTollNumber() && !this.appComp.countryListShow && appComp?.tenantContent?.header?.tel_number"
                    class="tollfree-txt d-none d-lg-block grade d-inline-block"
                    ><a
                        href="tel:{{ appComp?.tenantContent?.header?.tel_number}}"
                        style="text-decoration: none; color: #fff"
                        >Offer Helpline -<strong>{{ appComp?.tenantContent?.header?.tel_number}}</strong></a
                    ></span
                > -->
                <span style="cursor: pointer;" class="tollfree-txt d-none d-lg-block grade"><a
                        href="tel:{{ appComp?.tenantContent?.header?.tel_number }}"
                        style="text-decoration: none; color: #fff"><span>{{ "store-listing.header.toll-free" | translate
                            }}
                        </span>
                        <strong>{{
                            appComp?.tenantContent?.header?.tel_number
                            }}</strong></a></span>

                <!--  <select class="header-language-selection">
                    <option value="en">EN</option>
                    <option value="en">EN</option>
                    <option value="en">EN</option>
                </select> -->


                <select class="header-language-selection d-flex align-items-center justify-content-center" name="lng"
                    id="lng1" *ngIf="appComp.lngs.length>0" [(ngModel)]="this.appComp.language"
                    (change)="selectLangsChange($event)">
                    <option [value]="value.code" *ngFor="let value of appComp.lngs">
                        {{ value.code | uppercase }}
                    </option>
                </select>
                <!-- {{this.appComp.language + " language"}} -->
                <div class="header-country-selection-container" style="cursor: pointer;">
                    <img src="/assets/images/globe.svg" alt="" width="24" (click)="showCountries()" loading="lazy" />

                    <select name="lng" id="lng" (change)="selectChange($event)" [(ngModel)]="this.appComp.countryName"
                        class="header-country-selection d-flex align-items-center justify-content-center">
                        <option value="{{ value.code }}" *ngFor="let value of countryLists">
                            {{ value.name }}
                        </option>
                    </select>
                </div>
                <!-- <select class="header-language-selection">
                    <option value="en">EN</option>
                    <option value="en">EN</option>
                    <option value="en">EN</option>
                </select> -->

                <!-- <select class="language-select-bx d-flex align-items-center justify-content-center d-lg-none" *ngIf="!this.appComp.countryListShow"   name="lng" id="lng"  [(ngModel)]='this.appComp.language' (change)="selectLangsChange($event)">
                    <option value="{{value.code}}" *ngFor="let value of lngs">{{value.name}} </option>
                 </select> -->
            </div>
        </div>
    </header>
    <div class="page-not-found">
        <h1 class="errhd">{{"page-not-found.pnfnum" | translate}} <span class="err">{{"page-not-found.pnferr" |
                translate}}</span></h1>
        <p>{{"page-not-found.pngext" | translate}}</p>
        <p class="secpara">{{"page-not-found.pngadd"| translate}}</p>
        <u><a routerLink="/">{{"page-not-found.pngrute" | translate}}</a></u>
    </div>
    <section class="content">
        <div class="section-bg-color store-content redeem-offers-cont" id="top">
            <div class="container">
                <div class="row clearfix ">
                    <div class="col-lg-6 pl-4">
                        <!-- <div class="view-offer-bx store-view-offer-bx"> -->
                        <div class="assistance_placeholder">
                            <h2 class="section-title">{{"store-listing.section-title-whats-latest" | translate}}</h2>
                            <div id="offerGallery">
                                <!-- <app-offers   [images]='desktopBanner'></app-offers> -->
                                <carousel #innerOffersCaraousel style="z-index:1;" (click)="showPages($event)"
                                    [borderRadius]="20" [cellsToShow]="1" [cellWidth]="552" [height]="height"
                                    *ngIf="bannerImages && bannerImages.length>0" [images]="bannerImages">
                                </carousel>
                            </div>
                            <div class="thumbnail-gallery-bx">
                                <div class="gallery-thumb">
                                    <ul id="thumb-list_holder1" class="clearfix">
                                    </ul>
                                    <a href="javascript:;" class="prev-arrow"></a>
                                    <a href="javascript:;" class="next-arrow"></a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-6  pl-4">
                        <div class="assistance_placeholder">
                            <!-- <app-get-assistance [width]="width" [height]="assistanceHeight"></app-get-assistance> -->
                            <app-get-assistance></app-get-assistance>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-bg-color">
                <div class="about-section">
                    <div class="container">
                        <h4>
                            {{
                            "store-listing.store-site.about-lenovo.title"
                            | translate
                            }}
                        </h4>
                        <!-- <p [innerHTML]="storeDetails.aboutStore"></p> -->
                        <p>
                            Lenovo is a US$43 billion global Fortune 500 company
                            and a leader in providing innovative consumer,
                            commercial, and data center technology. Our
                            portfolio of high-quality, secure products and
                            services covers PCs (including the legendary Think
                            and multimode Yoga brands), workstations, servers,
                            storage, networking, software (including ThinkSystem
                            and ThinkAgile solutions), smart TVs and a family of
                            mobile products like smartphones (including the
                            Motorola brand), tablets and apps.
                        </p>
                    </div>
                </div>
            </div>
            <div class="faqs-section">
                <div class="container py-4">
                    <h4 class="section-title">
                        {{ "store-listing.store-site.faqs.title" | translate }}
                    </h4>
                    <!-- activeIds="ngb-panel-0" -->
                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
                        <ngb-panel *ngFor="let faq of tenantContent" title="{{ faq.title }}">
                            <ng-template ngbPanelContent>
                                {{ faq.description }}
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <div class="most-search-bx">
                <div class="container">
                    <h4>
                        {{
                        "store-listing.store-site.most-searched.title"
                        | translate
                        }}
                    </h4>
                    <ul class="pl-3 pr-5">
                        Lenovo Exclusive Store | Lenovo Store | Where To Buy
                        Computer | Old Laptop Buy And Sell | Computer Hardware
                        Store | Lenovo Store Locator | Computers & Laptops
                        Accessories | Best Gaming Laptops | Best Laptop Shop |
                        Laptop Store Near Me | Best Buy Laptops | Desktop Store
                        | Laptops And Computer Store | Lenovo Laptop price |
                        Lenovo Service Center | Best Lenovo store | Yoga Laptops
                        | Lenovo Premium Laptops | Best Lenovo Laptop Store |
                        Lenovo Shop Near Me | Lenovo Laptops | Lenovo Laptop
                        Store Near Me | Lenovo Laptop Store | Best Place To Buy
                        Laptop | Where To Buy Laptop | Computer Repair Service |
                        Laptop Repair Store | Buy A Lenovo | Laptop Dealers |
                        Premium Laptops Store | Laptop Shop Near Me | Laptop
                        Deals | Laptops For Sale | Computer Store and Shop |
                        Laptop Store and shop | Where to Buy Lenovo Laptops |
                        Offers on Lenovo Laptops | Legion Laptops | Ideapad
                        Laptops | Lenovo Gaming Laptop | Lenovo Showroom |
                        Lenovo Exclusive Store Near Me
                    </ul>
                    <!-- <p><a href="javascript:;">Best Lenovo Store</a> | <a href="javascript:;">Lenovo Exclusive Store Near
                      Me</a> | <a href="javascript:;">Lenovo Exclusive Store</a> | <a href="javascript:;">Lenovo
                      Store</a></p> -->
                </div>
            </div>
        </div>
    </section>
    <!-- content end -->

    <app-footer [sellertype]="isLoggedIn()"></app-footer>
</div>