import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AdminBannerImages } from '../../models/banner';
import { CarouselComponent } from 'angular-responsive-carousel';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, USE_DEFAULT_LANG } from '@ngx-translate/core';
import { ApacService } from '../../services/apac.service';
import { AppComponent } from '../../app.component';
import { SharingService } from '../../services/sharing.service';
import { UtilitiesService } from '../../services/utilities.service';
import { Location } from '@angular/common';
import { debug } from 'console';
declare var $: any;

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  @Input() data: any;
  @Input("country") country: String = "in";
  @Input('height') height: number = 250;
  @Input('width') width: number = 400;
  @ViewChild("innerOffersCaraousel") innerOffersCaraousel?: CarouselComponent
  @Input('images') images: any[] = [
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/4329.jpg"
    },
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/Legion%205i%20Pro_1284x896_300%20Dpi.png"
    }
  ];
  formattedAddress!: string;
  con: any = "";
  lng: any = "";
  storeDetails!: any;
  isDesktop: boolean = true;
  offersHeight: number = 267;
  banners: AdminBannerImages[] = [];
  assistanceHeight: number = 267;
  assistanceWidth: number = 550;
  sellertype: boolean = true
  sharedObject: any;
  routeTo: any;
  constructor(
    private route: ActivatedRoute,
    public appComp: AppComponent,
    private router: Router,
    public apac: ApacService,
    public utility: UtilitiesService,
    public location: Location,
    public translate: TranslateService,
    private sharingService: SharingService, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    console.log(this.data)
    if ($(window).width() <= 767) {
      this.isDesktop = false;
      this.offersHeight = ($(window).width() / (1284 / 896)) - 12;
      const calH = ($(window).width() / (1500 / 700)) - 30;
      this.assistanceHeight = calH < 156 ? 156 : calH;
      this.assistanceWidth = (1500 / 700) * this.assistanceHeight;
    }
    if ($(window).width() <= 767) {
      this.isDesktop = false;
      this.offersHeight = $(window).width() / (1284 / 896) - 12;
    }
    this.sharedObject = this.sharingService.getSharedObject();
    console.log(this.sharedObject);

    console.log(this.location.path());
    if (this.location.path() != "") {
      var pathArray = this.location.path().split("/");
      var pathParams = pathArray.filter(item => item != "");
      console.log(pathParams);
      this.con = pathParams[0];
      this.lng = pathParams[1];
    }
    //this.routeTo="/"+this.con+"/"+ this.lng+"/"
    if (this.lng == undefined) this.con = 'in';

    this.getAllcontent()

  }
  isLoggedIn(): boolean {
    return true
  }

  open() {

  }
  go(_to: string) {
    if (_to == 'home' || _to === 'stores') {
       window.location.href = `/`;
      this.router.navigate([this.con, this.lng]);
    } else if (_to === 'warranty') {
      window.open(`https://pcsupport.lenovo.com/` + this.appComp.countryName + `/en/warrantylookup`, '_blank');
    } else if (_to === 'website') {
      window.open(`https://lenovo.com/` + this.appComp.countryName, '_blank');
    }


    this.appComp.localeId = localStorage.getItem("localeId")

    let con = this.appComp.localeId.split("-")[1].toLocaleLowerCase();
    let lng = this.appComp.localeId.split("-")[0].toLocaleLowerCase();

    this.router.navigate([this.con, this.lng]);
  }

  selectLangsChange(ev: any) {
    localStorage.removeItem('lat');
    localStorage.removeItem('lng');
    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    this.appComp.isLoad = true;
    this.appComp.localeSub.next(ev.target.value);

    this.appComp.language = ev.target.value;
    this.appComp.localeId =
      ev.target.value + '-' + this.appComp.countryName;

    if (window.innerWidth > 991) {
      if (this.appComp.locationOn.value == true) {
        this.appComp.locationOn.next(true);
      } else {
        this.appComp.locationOn.next(false);
      }
    }
    this.appComp.selected = true;


    // this.appComp.SetLanguageAfterCountryDropdown(
    //     this.appComp.countryName,
    //     ev.target.value
    // );
  }

  countryLists: any = []
  showCountries() {
    this.appComp.isLoad = true;

    if (window.innerWidth < 991) {
      this.appComp.countryListShow = true;
      $('#countries').show().animate({ left: '0' }, 300);
      this.appComp.isLoad = false;
    }

    this.setLanguageAfterCountryDrop()

    setTimeout(() => {
      if (window.innerWidth < 991) {
        this.appComp.countryListShow = true;
        $('#countries').show().animate({ left: '0' }, 300);
        this.appComp.isLoad = false;
      }
    }, 600)


  }

  setLanguageAfterCountryDrop() {

  }

  selectChange(ev: any) {
    if (window.innerWidth < 991) {
      this.appComp.countryListShow = true;
    }
    this.appComp.isLoad = true;
    this.appComp.countrySub.next(ev.target.value);
    this.appComp.countryName = ev.target.value;
    let c = this.appComp.languages.find((c) => c.code === ev.target.value);
    this.appComp.lngs = c.langList;
    localStorage.removeItem('lat');
    localStorage.removeItem('lng');
    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    this.appComp.locationOn.next(false);
    this.appComp.SetLanguageAfterCountryDropdown(
      this.appComp.countryName,
      null
    );
    this.appComp.selected = false;
  }


  close() {

  }

  ngAfterViewInit() {

  }

  tenantContent: any = []
  getAllcontent() {
    this.translate.setDefaultLang(this.lng || 'en');
    this.translate.use(this.lng);
    this.apac.getTenantContents().subscribe((value) => {
      this.apac.contents$.next(value);

      let data = value[this.con];
      console.log(data)
      this.banners = data.bannerimages.images;
      this.appComp.tenantContent = data;
      this.tenantContent = data.faq;
      this.utility.banners.next(data.bannerimages.images);
      this.ngbannerImages(data.bannerimages.storeImages);

      this.appComp.popCities = data.cities_to_display_when_location_off;
      this.apac.supportedcountrylang().subscribe(conAndLang => {
        if (conAndLang.length > 0) {
          if (Array.isArray(conAndLang)) {
            this.appComp.languages = conAndLang.sort((a: object | any, b: object | any) => a.name.localeCompare(b.name));
            this.appComp.countryName = this.con;
            this.countryLists = []
            this.appComp.lngs = conAndLang;
            this.apac.languages$.next(this.appComp.languages);
            if (this.countryLists.length == 0) this.countryLists = conAndLang;

            this.getCountyAndLang(this.appComp.countryName);

          }
        }
      })
    })
  }


  bannerImages: any[] = []
  ngbannerImages(images: any[]) {
    let counter = 0;
    this.bannerImages = [];
    $('#thumb-list_holder').html('');
    for (let img of images) {
      this.bannerImages.push({ path: img.src });
      $('#thumb-list_holder').append(`<li>
        <a href="javascript:" id="offer_thumb_id_`+ (counter++) + `">
            <img src="`+ img.src + `"   width="100%" height="auto" alt="" class="img-fluid">
        </a>
        </li>`);
    }
  }


  showPages(ev: any) {
    if ($(ev.target).hasClass('carousel-arrow')) {
      return;
    }
    const url = this.images[this.getCurrentSlide()].url;
    if (url) {
      window.open(url, '_blank');
    }
  }
  getCurrentSlide() {
    return this.innerOffersCaraousel?.slideCounter;
  }
  showPage(ev: any) {
    const url = `/blogs/${this.country}/`;
    if (url) {
      window.open(url, '_blank');
    }
  }
  ngDoCheck() {

  }
  countries: any[] = []
  getCountyAndLang(en: any) {
    this.appComp.languages.forEach((country) => {
      if (country.code == en) {
        this.countries = country;
        this.appComp.lngs = country.langList;
        if (this.appComp.language != "en") {
          this.appComp.language = country.langList.filter((c: any) => c.code == this.appComp.language)[0].code;
        }
        this.appComp.localeId =
          this.appComp.language + '-' + this.appComp.countryName;

      }
    });
  }
}
